/* SMOOTH SCROLLING CONTROLS */
.frontend-page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100vh;
  width: 100%;
  /* border: #fff solid 5px; */
}

/* TITLE CONTAINER */
.frontend-landing-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100vh;
  color: rgb(247, 238, 227);
  /* border: solid green 1px; */
}

.frontend-title-container {
  display: flex;
  width: 47%;
  margin-top: -100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border: 1px green solid; */
}
.frontend-title-container em {
  font-size: 14rem;
  z-index: 999;
}
.front {
  margin-top: 50px;
  margin-bottom: -20px;
}
.end {
  margin-left: 120px;
}

.title-top-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.title-left-frontend {
  display: flex;
  flex-direction: column;
  text-align: center;
  z-index: 100;
  overflow: visible;
  /* border: solid #fff; */
}

.react-icon {
  width: 50px;
  height: 50px;
}

/* MIDDLE SECTION */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.frontend-white-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  color: #141213;
  /* border: solid green; */
  background-color: rgb(247, 238, 227);
}

.frontend-white-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(247, 238, 227);
  z-index: -1; /* Place background behind text */
}

.frontend-clipped-text {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  background: url(https://d34u8crftukxnk.cloudfront.net/slackpress/prod/sites/7/0_Qpa57tZ9qJ0Fu2ZR.gif);
  background-size: contain;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-container {
  margin-top: -200px;
  width: 100%;
  height: 100lvh;
  text-align: end;
}

.frontend-middle-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  color: #141213;
  width: 100%;
  height: 90vh;
  /* border: 10px dotted black; */
}

@media screen and (max-width: 720px) {
  .title-container {
    margin-top: 185px;
    overflow: visible;
  }
  .frontend-title-container {
    width: 100%;
    align-items: start;
    overflow: visible;
  }
  .frontend-title-container em {
    font-size: 6rem;
    width: 100%;
  }

  .title-left-frontend {
    margin-top: 100px;
    text-align: start;
    width: 80%;
    overflow: visible;
  }
  .title-top-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
  }
  .react-icon {
    margin-top: 55px;
    margin-left: 5px;
    height: 40px;
    width: 40px;
  }
}

/* BOTTOM SECTION */
.frontend-black-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #141213;
  color: rgb(247, 238, 227);
}

.frontend-bottom-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  /* border: solid green 2px; */
}

.frontend-bottom-container {
  display: flex;
  justify-content: start;
  padding-top: 90px;
  align-items: center;
  flex-direction: column;
  width: 100vw; /* Corrected */
  height: 90vh;
  overflow: hidden; /* Added */
  color: #141213;
  mix-blend-mode: difference;
  /* border: solid green; */
  background-color: rgb(247, 238, 227);
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@media screen and (max-width: 720px) {
  .frontend-bottom-content {
    width: 90%;
  }
}
