@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yesteryear&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allura&display=swap");
@import url("https://use.typekit.net/mwc1yot.css");

.app {
  background-color: #141213;
}

html {
  font-family: "Source Code Pro", monospace;
  font-size: 16px;
}
a {
  color: none;
  text-decoration: none;
}
a:visited {
  color: none;
  text-decoration: none;
}

em {
  display: contents;
  text-align: bottom;
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: italic;
  text-transform: lowercase;
  overflow: visible;
}

b {
  display: contents;
  font-family: "adobe-garamond-pro", serif;
  font-weight: 400;
  font-style: italic;
  line-height: 1rem;
  text-transform: lowercase;
  font-size: 10rem;
  overflow: visible;
}
.title-container-L b {
  font-size: 7rem;
  margin-top: -50px;
}

body {
  margin: 0px;
  padding: 0px;
  background-color: #141213;
}

h1 {
  font-size: 10rem;
  line-height: 10%;
  padding-bottom: 5px;
  font-weight: 900;
}

h2 {
  font-size: 8rem;
  line-height: 1%;
  font-weight: 900;
}

h3 {
  display: flex;
  flex-shrink: 1;
  font-size: 4rem;
  line-height: 10%;
  font-weight: 900;
}

h4 {
  display: flex;
  flex-shrink: 0.5;
  font-size: 1rem;
  line-height: 10%;
  font-weight: 900;
}

p {
  display: flex;
  font-size: 1.2rem;
  font-weight: 400;
  text-transform: uppercase;
}

li {
  display: flex;
  flex-shrink: 1;
  font-size: 0.8rem;
  font-weight: 100;
}

@media screen and (max-width: 720px) {
  h1 {
    font-size: 4rem;
    line-height: 1%;
    padding-bottom: 10px;
  }
  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
    font-weight: 400;
    text-transform: uppercase;
  }
  em {
    font-size: 4rem;
  }
  p em {
    font-size: 2rem;
  }
  b {
    font-size: 5rem;
  }
  li {
    font-size: 0.7rem;
  }
  .title-container-L b {
    font-size: 2rem;
  }
}

/* CONTAINERS */

.black-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  width: 100vw;
  height: 95vh;
  background-color: #141213;
  color: rgb(247, 238, 227);
}

.white-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  flex-direction: row;
  width: 100lvw;
  height: 95lvh;
  overflow: hidden;
  color: #141213;
  mix-blend-mode: difference;
  /* border: solid green; */
  background-color: rgb(247, 238, 227);
}

/* SMOOTH SCROLLING CONTROLS */
.scroll-section {
  position: relative;
  scroll-snap-align: start;
  scroll-behavior: smooth;
}
.scroll-container {
  scroll-behavior: smooth 1000ms;
}

.to-top {
  padding: 10px;
  border: solid 2px rgb(247, 238, 227);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: #141213;
  color: rgb(247, 238, 227);
  font-weight: 600;
  position: fixed;
  bottom: 0; /* Align to the bottom */
  left: 80%;
  transform: translateX(-80%);
  z-index: 9999;
  text-decoration: none;
}

@media screen and (max-width: 720px) {
  .to-top {
    bottom: 0; /* Align to the bottom */
    left: 60%; /* Align to the center */
    transform: translateX(80%); /* Center horizontally */
    border-bottom: none;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 0.6s ease-out 2.9s;
}

.intro-pop-up-div {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: calc(100vh + 20px);
  z-index: 100;
  transition: ease-out 1s;
  background-color: #141213;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.intro-pop-up {
  display: flex;
  justify-content: center;
  z-index: 997;
}

.into-pop-up {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  color: rgb(247, 238, 227);
  /* border: solid green; */
  background-color: rgb(247, 238, 227);
  z-index: 997;
}
.into-pop-up::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #141213;
  z-index: -1; /* Place background behind text */
}

.pop-up-clipped-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background: url(https://gifdb.com/images/high/computer-system-coding-j3szfjv9fwb5at9x.gif);
  filter: invert(100%) saturate(0%) sepia(05%);
  background-size: contain;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 200px;
  /* border: solid green; */
}

/* Video Cards and Scroll */

.text {
  position: absolute;
  top: 50;
  /* width: 100%;
  height: 80%; */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: cell;
  opacity: 0;
  z-index: 999;
  overflow: hidden;
  transition: opacity 0.3s ease, visibility 0s 0.3s;
}

.text:hover {
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0s 0s;
}
.title-link {
  color: black;
}
.title-link:hover {
  color: rgb(43, 43, 115);
}

.project-tech-stack {
  padding: 5px 10px;
  border: solid 2px rgb(247, 238, 227);
  text-align: center;
  border-radius: 10px;
  margin: 5px;
  color: #141213;
  background-color: rgb(247, 238, 227);
  z-index: 999;
  font-size: 14px;
  font-weight: bold;
}

.project-tech-stack:nth-child(1) {
  top: 5%; /* Adjust position for the first span */
  left: 10%;
}
.project-tech-stack:nth-child(2) {
  top: 5%;
  left: 20%;
}
.project-tech-stack:nth-child(3) {
  top: 5%;
  left: 30%;
}
.project-tech-stack:nth-child(4) {
  top: 5%;
  left: 40%;
}
.project-tech-stack:nth-child(5) {
  top: 5%;
  left: 50%;
}

.project-tech-stack:nth-child(6) {
  top: 5%;
  left: 60%;
}

.video {
  width: 73%;
  min-height: 450px;
  max-height: 450px;
  z-index: 997;
  border-radius: 50px;
  filter: saturate(0.8) sepia(05%);
  background-color: #141213;
  padding-top: 40px;
  padding-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 720px) {
  .video {
    max-width: 100vw;
    min-height: 175px;
    max-height: 175px;
    margin-top: -15px;
    z-index: 998;
    border-radius: 20px;
    filter: saturate(0.8) sepia(05%);
    margin-top: 40px;
    margin-bottom: -10px;
    background-color: #141213;
    padding-top: 60px;
    padding-bottom: 60px;
    position: relative;
  }
  .text {
    height: 90%;
  }
}

.section-container {
  display: flex;
  max-width: 100vw;
  height: 50%;
  margin: auto;
  justify-content: start;
  align-items: center;
}

.product-list-container {
  position: relative;
  overflow-x: auto;
  cursor: pointer;
}

.product-list {
  display: flex;
  flex-direction: row;
  margin: 10px;
  height: 80%;
}

.product-card {
  position: relative;
  background-color: rgb(247, 238, 227);
  flex: 0 0 auto;
  width: 60%;
  height: 50%;
  border: solid 4px #141213;
  margin: 10px;
  border-radius: 50px;
  color: #141213;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  z-index: 998;
}

.product-card video {
  width: 97%;
  height: 100%;
  margin-top: 10px;
  border-radius: 50px;
  overflow: hidden;
  z-index: 999;
}

.dot {
  cursor: pointer;
  display: inline-block;
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  transition: background-color 1s ease;
}

.active,
.dot:hover {
  background-color: #141213;
}

.fade {
  animation-name: fade;
  animation-duration: 2s;
}

.scroll-down {
  position: absolute;
  bottom: 1px;
  width: 5%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (max-width: 600px) {
  .scroll-down {
    width: 10%;
    left: 45%;
    transform: translateX(-30%);
  }
  .product-card {
    width: 90%;
    margin: 20px 10px 20px 0px;
  }
  .section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 0 20px;
  }
}
