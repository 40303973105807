.App {
  text-align: center;
  width: 100lvw;
  height: 100lvh;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* ARROW */

.arrow {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  z-index: 999;
  opacity: 0.8;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: clamp(800px, 100%, 2000px);
  height: clamp(800px, 100%, 2000px);
  z-index: 997;
  pointer-events: none;
  mix-blend-mode: difference;
  opacity: 40%;
  filter: brightness(70%);
}

@media screen and (max-width: 720px) {
  .overlay {
    width: clamp(800px, 100%, 1200px);
    width: clamp(800px, 100%, 1200px);
  }
}
