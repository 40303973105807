nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 996;
  display: block;
  flex-direction: column;
  width: 100lvw;
  height: 28px;
  padding-bottom: 20px;
  background: #141213;
  align-items: center;
  /* border: solid rgb(247, 238, 227); */
  font-size: 75%;
}

nav ul {
  display: flex;
  flex-direction: row;
  margin-left: -40px;
  width: 100%;
  flex-shrink: 1;
}

nav li {
  width: 20%;
  list-style: none;
  text-transform: uppercase;
}

nav li a {
  display: block;
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 997;
  color: rgb(247, 238, 227);
  box-sizing: border-box;
  text-decoration: none;
}

nav li a:hover {
  color: #141213;
}
nav li:first-child a {
  border-left: 5px solid rgb(247, 238, 227);
}
nav li:nth-child(2) a {
  border-left: 5px solid rgb(247, 238, 227);
}
nav li:nth-child(3) a {
  border-left: 5px solid rgb(247, 238, 227);
}
nav li:nth-child(4) a {
  border-left: 5px solid rgb(247, 238, 227);
}
nav li:last-child a {
  border-left: 5px solid rgb(247, 238, 227);
}

nav li a:after {
  content: "";
  height: 100%;
  left: 0;
  top: 0;
  width: 0px;
  position: absolute;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 1s ease 0s;
  z-index: -1;
}

nav li a:hover:after {
  width: 100%;
}
nav li:first-child a:after {
  background: rgb(247, 238, 227);
}
nav li:nth-child(2) a:after {
  background: rgb(247, 238, 227);
}
nav li:nth-child(3) a:after {
  background: rgb(247, 238, 227);
}
nav li:nth-child(4) a:after {
  background: rgb(247, 238, 227);
}
nav li:last-child a:after {
  background: rgb(247, 238, 227);
}

.contact {
  position: fixed;
  right: 0;
  margin-top: -10px;
  height: 10lvh;
  width: 100lvw;
  background-color: #141213;
  color: rgb(247, 238, 227);
  display: block;
  text-align: end;
  justify-content: end;
  align-items: center;
  border: solid 2px rgb(247, 238, 227);
  scroll-behavior: none;
  overflow: hidden;
  z-index: 100;
  flex-direction: row;
}
.content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.icon {
  color: rgb(247, 238, 227);
}
.icons {
  grid-column: 2 / 1 span;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  /* border: white solid 3px; */
}

@media screen and (max-width: 720px) {
  .contact {
    height: 10lvh;
    width: 99lvw;
    align-items: center;
  }
  .content {
    justify-content: end;
    align-items: center;
  }
  .contact-title {
    display: none;
  }
}
