/* SMOOTH SCROLLING CONTROLS */
.design-page-container {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100lvh;
  width: 100lvw;
}
.icon-cycle-design {
  position: absolute;
  left: 0.5%;
  bottom: 2.5%;
}

/* FIRST SECTION */

.design-landing-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  padding-top: 50px;
  height: 94lvh;
  width: 100lvw;
  overflow: hidden;
  color: rgb(247, 238, 227);
  /* border: solid #fff; */
}

.title-container-L {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
  writing-mode: vertical-rl;
  text-orientation: upright;
}

.title-container-R {
  margin-top: -50px;
  width: 100%;
  height: 100lvh;
  text-align: end;
  /* border: 2px solid green; */
}

.design-clipped-text {
  text-transform: uppercase;
  background: url(https://europe1.discourse-cdn.com/figma/original/3X/7/e/7ebcf069b6f979bdd3aa1cc9b8ae97f770067567.gif);
  background-size: fill;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  filter: saturate(0.8) sepia(20%);
}

.design-title {
  font-size: 4rem;
}

/* MIDDLE SECTION */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 720px) {
  .title-container-L {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
  }

  .title-container-R {
    position: absolute;
    top: 35%;
    text-align: end;
    /* border: 2px solid green; */
  }

  .icon-cycle-design {
    position: absolute;
    right: 0;
    bottom: 1%;
    /* border: green solid 1px; */
  }
}

@media screen and (max-width: 720px) {
  .design-white-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100lvw;
    height: 100lvh;
    overflow: hidden;
    color: #141213;
    mix-blend-mode: difference;
    background-color: rgb(247, 238, 227);
  }
}

/* BOTTOM */

.design-bottom-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-top: -20px;
  /* border: solid green 2px; */
}

.arrow-icon-design-video,
.arrow-icon-design-video svg {
  position: relative;
  color: rgb(
    247,
    238,
    227
  ) !important; /* Use !important to ensure the color change takes precedence */
}
