/* SMOOTH SCROLLING CONTROLS */
.about-me-page-container {
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100lvh;
  width: 100lvw;
}

/* FIRST SECTION */

.about-me-landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  width: 100vw;
  height: 98vh;
  color: rgb(247, 238, 227);
  /* border: green solid 1px; */
}

.about-me-landing-container h1 {
  margin-bottom: -10px;
  text-align: left;
  width: 75%;
}

.www-icon {
  margin-top: 75px;
  margin-left: 15px;
}

.creative {
  margin-bottom: -57px;
  text-align: left;
  width: 75%;
}

/* MIDDLE SECTION */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.clipped-white-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  width: 100%;
  height: 95lvh;
  color: #141213;
  /* border: solid green; */
  background-color: rgb(247, 238, 227);
}
.clipped-white-container::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(247, 238, 227);
  z-index: -1; /* Place background behind text */
}

.clipped-text {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  background: url(https://d34u8crftukxnk.cloudfront.net/slackpress/prod/sites/7/0_Qpa57tZ9qJ0Fu2ZR.gif);
  background-size: contain;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-container {
  padding-top: 100px;
  width: 100%;
  text-align: end;
  /* border: solid green; */
}

/* BOTTOM SECTION */
.about-me-bottom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100lvh;
  background-color: #141213;
  color: rgb(247, 238, 227);
}

.about-me-bottom-content {
  display: flex;
  margin-top: -20px;
  width: 50%;
  height: fit-content;
  flex-direction: column;
  justify-content: center;
  /* border: solid green 2px; */
}
.icon-cycle-about-me {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 60px;
  margin-left: 10px;
}

@media screen and (max-width: 768px) {
  .about-me-bottom-content {
    width: 90%;
  }
  .www-icon {
    position: absolute;
    right: 2;
    top: 23%;
  }
  .about-me-landing-container h1 {
    margin-left: 7px;
    margin-bottom: 0px;
    margin-left: -80px;
  }
  .creative {
    margin-bottom: -14px;
    margin-top: -50px;
    margin-left: -80px;
  }
  .title-container {
    padding-top: 0;
  }
  /* .icon-cycle-about-me {
    position: relative;
    display: flex;
  } */
}
