.slide-in {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vmax;
  background-color: black;
  transform-origin: bottom;
}

.slide-out {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vmax;
  background-color: black;
  transform-origin: top;
}

.scroll {
  scroll-behavior: smooth;
}
