/* SMOOTH SCROLLING CONTROLS */
.git-hub-page-container {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  height: 100vh;
  width: 100vw;
  /* border: #fff solid 5px; */
}

/* FIRST SECTION */
.git-hub-section {
  display: flex;
  background-color: #141213;
  justify-content: center;
  color: rgb(247, 238, 227);
  width: 100lvw;
  height: 100lvh;
  /* border: #fff solid 5px; */
}

/* TITLE CONTAINER */
.git-hub-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
  height: 95lvh;
  width: 100lvw;
  color: rgb(247, 238, 227);
  /* border: #fff solid 5px; */
}

.heading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  width: 100%;
  /* border: solid 2px green; */
}

.git-hub-profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  margin-bottom: 50px;
  /* border: solid 2px green; */
}

.git-hub-chart {
  margin-top: 10px;
  width: 90%;
}
.git-hub-profile-picture {
  width: 10%;
  border-radius: 100px;
  margin-top: -140px;
  margin-bottom: 40px;
  filter: saturate(70%);
}

@media screen and (max-width: 720px) {
  .git-hub-profile {
    width: 70%;
  }
  .git-hub-chart {
    width: 350px;
  }
  .git-hub-profile-picture {
    width: 50%;
  }
}

/* MIDDLE SECTION */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
